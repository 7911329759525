import { React, useState, useEffect } from 'react';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import { useSelector, useDispatch } from 'react-redux';
import { Advertisepdfdata } from '@store/cinemas/cinemasActions';
import CinemaService from '@apiService/cinemaService';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import AdvertisingPdf from '@assets/pictures/pdf/QFX Cinemas DTA & Branding Proposal-2079-80.pdf';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const AdvertiseWithUs = ({ t }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [numPages, setNumPages] = useState(null);
  const [advertiseDataNew, setadvertiseDataNew] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const cinemas = useSelector((state) => state.cinemas);

  const { advertiseLoading, advertiseData } = cinemas;

  const Advertisepdfdata = () => async (dispatch) => {
    try {
      // dispatch({ type: ADVERTISE_PDF_DATA });
      // const { data } = await CinemaService.GetAdvertisePdfData();
      const response = await CinemaService.GetAdvertisePdfData();
      const { data } = response;
      if (data?.status) {
        setadvertiseDataNew(data.Records);
      }
    } catch (error) {
      console.log(error, 'errorrrr');
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(Advertisepdfdata());
    console.log('Outside Dispatch');
  }, []);
  let vip_pdf_path = advertiseDataNew.map((x) => {
    return x.vip_path;
  });
  // console.log(advertiseDataNew, 'advertiseDataNew');
  console.log(vip_pdf_path[0], 'vip_path');
  return (
    <>
      <div className="tnc_new align_new">
        <div className="left_right_align">
          <BreadCrumb
            firstHalf={`${t('More')} >`}
            secondHalf={`${'Advertise With Us'}`}
          />
          <CenteredTitle firstHalf={`${'Advertise With Us'}`} secondHalf={''} />
        </div>
        <section className="container-fluid opening_hours">
          <nav
            style={{ textAlign: 'center', gap: '10px' }}
            className="d-flex justify-content-center align-items-center mb-3"
          >
            <button onClick={goToPrevPage} className="pdf-button">
              Prev
            </button>
            <button onClick={goToNextPage} className="pdf-button">
              Next
            </button>
            <p>
              Page {pageNumber} of {numPages}
            </p>
          </nav>

          {/* <p>{advertiseDataNew.vip_path}</p> */}
          <Document
            file={vip_pdf_path[0] ? vip_pdf_path[0] : ''}
            // file="https://d2h4kll8mltez0.cloudfront.net/S3/uploads/1680812670341-qfx_cinemas_dta__branding_proposal207980.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.log}
            className="pdf-document"
          >
            <Page pageNumber={pageNumber} />
            {/* {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            className="pdf-page"
            pageNumber={index + 1}
            width="200"
          />
        ))} */}
          </Document>
          {/* <!---Old Design---> */}
          {false && (
            <article className="col-lg-10 col-xl-12 tnc_back">
              {/* <p className="tnc_new_text_highlighted mb-2">WHY ADVERTISE WITH QFX</p> */}
              <div className="tnc_new">
                <h4 className="tnc_new mb-4">
                  {' '}
                  Imagine hundreds of captive and engaged audience of all
                  demographics in one place. That’s QFX Cinemas.
                </h4>

                <h4 className="tnc_new mb-4">
                  As one of the leading multiplex cinema chain business, QFX cinemas
                  has over 26 screens under its brand, covering 11 different
                  locations in 9 cities in Nepal!
                </h4>

                <h4 className="tnc_new mb-4">
                  Over the past few years, QFX has also widely engaged its customers
                  in online space through its App, website, social media platforms,
                  reaching thousands of people every hour.
                </h4>
              </div>

              <div className="static_page align_new mt-5">
                <h4 className="static_page_highlight_custom mb-4 text-primary">
                  Our Advertisement options include:
                </h4>
              </div>

              {/* First Row */}
              <div
                className={`row ${
                  isMobile || isTablet ? 'flex-column-reverse' : 'mb-4'
                }`}
              >
                <div
                  className={`col-md-12 col-xl-6 ${
                    isMobile || isTablet ? 'mt-2' : ''
                  }`}
                >
                  <img
                    className="img-fluid custom_img_about"
                    src="https://www.qfxcinemas.com/assets/images/ad-with-us-1.png"
                  />
                </div>

                <div
                  className={`col-md-12 col-xl-6 ${
                    !isMobile ? 'd-flex justify-content-center flex-column' : ''
                  }`}
                >
                  <h3
                    className={`static_page_highlight_custom text-primary font-weight-bold ${
                      isMobile || isTablet ? 'mt-4' : ''
                    }`}
                  >
                    ON-SCREEN ADVERTISEMENT
                  </h3>
                  <p>Digital Theater Advertisement (DTA)</p>
                </div>
              </div>

              {/* Second Row */}
              <div
                className={`row ${
                  isMobile || isTablet ? 'flex-column-reverse' : 'mb-4'
                }`}
              >
                <div
                  className={`col-md-12 col-xl-6 ${
                    isMobile || isTablet ? 'mt-2' : ''
                  }`}
                >
                  <img
                    className="img-fluid custom_img_about"
                    src="https://www.qfxcinemas.com/assets/images/ad-with-us-2.png"
                  />
                </div>

                <div
                  className={`col-md-12 col-xl-6 ${
                    !isMobile ? 'd-flex justify-content-center flex-column' : ''
                  }`}
                >
                  <h3
                    className={`static_page_highlight_custom text-primary font-weight-bold ${
                      isMobile || isTablet ? 'mt-4' : ''
                    }`}
                  >
                    OFF-SCREEN ADVERTISEMENT
                  </h3>
                  <ul>
                    <li>Digital Branding</li>
                    <li> LFD </li>
                    <li> Video Wall</li>
                    <li>Non Digital Branding</li>
                  </ul>
                </div>
              </div>

              <div>
                <span>For Advertisement at QFX Cinemas, please contact</span>
                &nbsp;
                <span>
                  <a href="maito:sanjeev@linez.com.np">sanjeev@linez.com.np</a>
                </span>
                &nbsp;
                <span>or call </span>
                <span>
                  <a href="tel:9801729961">9801729961</a>
                </span>
              </div>

              <div>
                <span>For Advertisement Details and Rates:</span>
                &nbsp;
                <span>
                  <a
                    href="https://teamquestnp-my.sharepoint.com/personal/girtri01_qfx_com_np/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fgirtri01%5Fqfx%5Fcom%5Fnp%2FDocuments%2FTQ%20Marketing%2FAdmin%2FQFX%20Rates%20and%20Forms%2FQFX%20Cinemas%20DTA%20%26%20Branding%20Proposal%2D2079%2D80%2Epdf&parent=%2Fpersonal%2Fgirtri01%5Fqfx%5Fcom%5Fnp%2FDocuments%2FTQ%20Marketing%2FAdmin%2FQFX%20Rates%20and%20Forms&ga=1"
                    target="_blank"
                  >
                    Click Here !
                  </a>
                </span>
              </div>

              {/* <div>
              <div className="m-2">CONTACT US TO ADVERTISE</div>
              <div className="d-flex">
                <div className="col-6 mb-0">
                  <label className="">
                    <input
                      type="text"
                      name=""
                      id=""
                      className="p-2"
                      placeholder="NAME"
                    />
                  </label>
                </div>
                <div className="col-6 mb-0">
                  <label className="">
                    <input
                      type="text"
                      name=""
                      id=""
                      className="p-2"
                      placeholder="Email"
                    />
                  </label>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-6 mb-0">
                  <label className="">
                    <input
                      type="text"
                      name=""
                      id=""
                      className="p-2"
                      placeholder="MOBILE"
                    />
                  </label>
                </div>
                <div className="col-6">
                  <label className="">
                    <input
                      type="text"
                      name=""
                      id=""
                      className="p-2"
                      placeholder="ORGANIZATION NAME"
                    />
                  </label>
                </div>
              </div>
              <div>
                <div>TYPE</div>
                <input
                  className="ml-2"
                  type="radio"
                  id="adv1"
                  name="advertise1"
                  value="On-Screen Advertising"
                />
                <label for="html">On-Screen Advertising</label>
                <input
                  className="ml-2"
                  type="radio"
                  id="adv2"
                  name="advertise1"
                  value="Off-Screen Advertising"
                />
                <label for="html">Off-Screen Advertising</label>
                <input
                  className="ml-2"
                  type="radio"
                  id="adv3"
                  name="advertise1"
                  value="Combo Packeges"
                />
                <label for="html">Combo Packeges</label>
              </div>
              <div>
                <input
                  className="p-2 w-100"
                  type="text"
                  placeholder="Corporate/Reg office location"
                />
              </div>
              <div>
                <input
                  className="p-2 w-100"
                  type="text"
                  placeholder="Leave Your Message"
                />
              </div>
              <div>
                <button type="submit" className="btn-main btn-main-lg mt-3 mt-lg-4">
                  Submit
                </button>
              </div>
            </div> */}
            </article>
          )}
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(AdvertiseWithUs);
